import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
//import SEO from "../components/seo"

const Disclaimer = () => {
  return (
    <Layout>
      <div className="disclaimer">
        <h2>Disclaimer</h2>
        <h3>Legal notice</h3>
        <p>
          Delivery Hero SE constantly checks and updates the information on its
          web pages. Despite the utmost care taken, we cannot rule out that some
          of the data may since have become outdated. Therefore, we cannot
          accept liability for the currency, accuracy and completeness of the
          information displayed. The same applies to all other web pages that
          can be reached via hyperlink. Delivery Hero SE is not responsible for
          the content of websites which can be reached via such links.
          Furthermore, Delivery Hero SE reserves the right to make changes or
          additions to the information displayed. Content and structure of the
          Delivery Hero SE website is protected by copyright. The reproduction
          of information or data, in particular the use of texts, text excerpts
          or illustrative material, requires our prior approval. We wish you an
          informative stay on our pages and would welcome any suggestions,
          requests or criticism you may have.
        </p>
        {/* <h2>Web Analytics</h2>
        This website uses Google Analytics, a web analytics service provided by
        Google, Inc. Google Analytics uses “cookies”, which are text files
        placed on your computer, to help the website analyze how users use the
        site. The information generated by the cookie about your use of the
        website (including your IP address) will be transmitted to and stored by
        Google on servers in the United States . Google will use this
        information for the purpose of evaluating your use of the website,
        compiling reports on website activity for website operators and
        providing other services relating to website activity and internet
        usage. Google may also transfer this information to third parties where
        required to do so by law, or where such third parties process the
        information on Google’s behalf. Google will not associate your IP
        address with any other data held by Google. You may refuse the use of
        cookies by selecting the appropriate settings on your browser, however
        please note that if you do this you may not be able to use the full
        functionality of this website. By using this website, you consent to the
        processing of data about you by Google in the manner and for the
        purposes set out above.
        <h2>Atlas Cookies Policy</h2>
        <p>
          Atlas uses cookies to help us support, provide, secure and understand
          its Services and Websites. For example, Atlas uses cookies to help:
        </p>
        <p>
          serve and deliver relevant ads to the right people across their
          browsers or devices;
        </p>
        <p>
          manage and optimize how ads are shown through our Services, such as
          how often you see an ad or the order of ads you see;
        </p>
        <p>
          measure and understand the effectiveness of ads, such as when and how
          you interact with the ads you see or related apps, sites, products and
          services;
        </p>
        <p>
          respect the choices you make such as opting out of receiving online
          behavioral ads from our Services on your browser or device;
        </p>
        <p>
          provide our customers or publishing partners with reports and insights
          regarding the effectiveness of their ads and our Services; and perform
          other business operations such as billing, research, infrastructure
          monitoring and performance, maintenance and fraud detection.
        </p>
        <p>
          Atlas also uses cookies when you access web browsers from a mobile
          device when they interact with third-party websites and advertisements
          that use or are integrated with our Services. Atlas also use software
          development kits (SDKs) to read, store and access information from
          your mobile device to help provide our Services as described above
          when mobile apps use or integrate with Atlas Services.
        </p> */}
      </div>
    </Layout>
  )
}

export default Disclaimer
